<template>
  <div class="app-container summary-page">
<!--    所有佣金统计保留4位小数(去掉无效的0)-->
    <eHeader
      ref='header'
      :query="query"
      @export-execl="exportExecl"
      @clear="clear"
      @toQuery="toQuery"
    />
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      stripe
      size="small"
      style="width: 100%;"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column prop="rank" label="序号"/>
      <el-table-column prop="custId" label="代理ID"/>
      <el-table-column prop="custName" label="代理姓名"/>
      <el-table-column prop="directInner" label="直接内佣($)"/>
      <el-table-column prop="indirectInner" label="间接内佣($)"/>
      <el-table-column prop="directCommission" label="外佣($)"/>
      <el-table-column prop="commissionAll" label="佣金总额($)"/>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initDataComm from '@/mixins/initData'
import { initData } from '@/api/data'
import { parseTime,calcDuringDate } from '@/utils/index'
import { export_json_to_excel,formatJson } from '@/vendor/Export2Excel'
import eHeader from '@/components/stat/commlist/header'
export default {
  name:'commlist',
  components: {
    eHeader
  },
  mixins: [initDataComm],
  props:{
  },
  data() {
    return {
      loading: false,
      sup_this: this,
      statistics:{
        totalVolume: '',
        totalDirectCommission: '',
        totalCommissionAll: '',
        totalDirectInner: '',
        totalIndirectInner: '',
      }
    }
  },
  created() {
    // this.$nextTick(() => {
    //   this.init()
    // })
  },
  methods: {
    parseTime,
    checkPermission,
    export_json_to_excel,
    formatJson,
    initData,
    beforeInit() {
      this.url = '/crm/queryStatisticsCommission';
      this.params = { page: this.page, size: this.size,export:false};
      const query = this.query;
      const type = query.type;
      const startTime = this.parseTime(query.startTime);
      const endTime = this.parseTime(query.endTime);
      if (type!=null && type!='') { this.params['type'] = type }
      if (this.query.custInfo!=null && this.query.custInfo!='') {
        this.params['custInfo'] = this.query.custInfo;
      }
      if(this.query.sourceLogin){
        this.params.sourceLogin = this.query.sourceLogin;
      }
      if (startTime!=null && startTime!='') {
        this.params['startTime'] = startTime;
      }
      if (endTime!=null && endTime!='') {
        this.params['endTime'] = endTime;
        if(startTime!=null && startTime!=''){
          let duringDays = calcDuringDate({
            endTime: endTime,
            startTime: startTime,
          });
          if(duringDays > 30){
            this.$message({
              message: '开始日期和结束日期不能超过30天',
              type: 'warning'
            });
            return;
          }
        }
      }

      return true;
    },
    exportExecl(){
      const query = this.query;
      const type = query.type;
      const custId = query.custId;
      const startTime = this.parseTime(query.startTime);
      const endTime = this.parseTime(query.endTime);
      if (type!=null && type!='') { this.params['type'] = type };
      if (custId!=null && custId!='') { this.params['custId'] = custId };
      if (startTime!=null && startTime!='') { this.params['startTime'] = startTime }
      if (endTime!=null && endTime!='') { this.params['endTime'] = endTime }
      this.params.export = true;
      this.initData('/crm/getStatisticsCommission', this.params).then(res => {
        this.$refs['header'].loading = false;
        const tHeader =['订单号','代理号','代理昵称','客户经理',
          '客户经理ID','交易品种','内佣类型', '内佣($)','外佣($)','佣金总额($)','手数','手续费($)','隔夜利息($)','盈利($)','平仓时间'];
        const filterVal =['orderId','custId','custName','managerName', 'managerId', 'symbol','commissionType', 'commissionAmount','commissionAmountForeign','commissionAll','volume','commission','storage','profit','closeTime'];
        const exportData = formatJson(filterVal,res.content);
        let fileName='佣金明细报表';
        const query = this.query;
        const type = query.type;
        const custId = query.custId;
        const startTime = this.parseTime(query.startTime);
        const endTime = this.parseTime(query.endTime);
        if (type!=null && type!='') { fileName = fileName+'-'+type };
        if (custId!=null && custId!='') { fileName = fileName+'-'+custId };
        if (startTime!=null && startTime!='') { fileName = fileName+'-'+this.parseTime(query.startTime) };
        if (endTime!=null && endTime!='') { fileName = fileName+'-'+this.parseTime(query.endTime) };
        export_json_to_excel(tHeader,exportData,fileName);
      }).catch(err => {
        this.$refs['header'].loading = false;
      })
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        switch (index) {
          case 0:
            sums[index] = '合计';
            break;
          case 3:
            sums[index] = `${this.statistics.totalDirectInner}`;
            break;
          case 4:
            sums[index] = `${this.statistics.totalIndirectInner}`;
            break;
          case 5:
            sums[index] = `${this.statistics.totalDirectCommission}`;
            break;
          case 6:
            sums[index] = `${this.statistics.totalCommissionAll}`;
            break;
          case 7:
            sums[index] = `${this.statistics.totalVolume}`;
            break;
        }
      });

      return sums;
    },
    clear(){
      this.query = {};
      this.toQuery();
    }
  }
}
</script>

<style scoped>

</style>
