<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select
            @change="toQuery"
            v-model="query.type"
            clearable
            placeholder="内佣类型"
            class="filter-item"
            style="width: 130px"
    >
      <el-option v-for="item in typeOptions" :key="item.keyValue" :label="item.key" :value="item.keyValue"/>
    </el-select>
    <el-input v-model="query.custInfo" clearable placeholder="代理姓名/ID" style="width: 150px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input
        v-model="query.sourceLogin"
        clearable
        placeholder="交易账号"
        type='number'
        style="width: 150px;"
        class="filter-item"
        @keyup.enter.native="toQuery"
    />
     <div class="filter-item">
      完成时间
      <el-date-picker
        style="width: 150px;"
        v-model="query.startTime"
        type="date"
        placeholder="开始时间">
      </el-date-picker>
      至
      <el-date-picker
        style="width: 150px;"
        v-model="query.endTime"
        type="date"
        placeholder="结束时间">
      </el-date-picker>
    </div>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-close" @click="clear">清空</el-button>
    <el-button
            v-if="checkPermission(['EXPORT_IB_COMMISSION','ADMIN'])"
            :loading="loading" class="filter-item" size="mini" type="primary" icon="el-icon-download" @click="exportExecl">导出</el-button>

  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      typeOptions:[
        //类型：1-直接内拥 2-间接内拥
        {key:'直接内佣',keyValue:1},
        {key:'间接内佣',keyValue:2}
      ],
      loading:false
    }
  },
  created() {
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$emit('toQuery');
    },
    clear() {
      this.$emit('clear');
    },
    exportExecl(){
      this.loading=true
      this.$emit('export-execl');
    }
  }
}
</script>
<style scoped>

</style>
